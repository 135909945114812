﻿//Bundle for the index page
import '@popperjs/core';
import { Modal } from 'bootstrap'
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import PincodeInput from 'pincode-input'
import 'pincode-input/dist/pincode-input.min.css'
import { ResourceManager } from '../Common/resourceManager.js';
import { CommonHelpers } from '../Common/helpers.js';

class SigninController {
    constructor() {
        this.firstAttempt = true;
        this.paneActive = "EMAIL";
        this.resourceManager = new ResourceManager();
        this.helpers = new CommonHelpers();
    }
    initializePage() {
        //Initialize phone component
        const input = document.querySelector("#txtMobilePhone");
        intlTelInput(input, {
            onlyCountries: ["us", "ca"],
            initialCountry: "us",
        });

        //Initialize pin componenet
        new PincodeInput('.pincode-input-container', {
            count: 6,
            secure: false,
            previewDuration: 200,
            onInput: (value) => {
                $("#pincode-input-hidden").val(value);
                if (value.length == 6 && this.firstAttempt) {
                    this.firstAttempt = false
                    this.checkUserPin(value);
                }             
            }
        });

        //Initialize modal
        new Modal(document.getElementById('mainModal'));
    }
    registerEvents() {
        //On keyup format phone
        let self = this;
        document.getElementById("txtMobilePhone").addEventListener('keyup', function (evt) {
            let phoneNumber = this;
            phoneNumber.value = self.helpers.phoneFormat(phoneNumber.value);
        });

        //Submit
        $("#signin-form").on("submit", function (e) {
            e.preventDefault();
            if (self.checkSubmitForm()) {
                $("#signinBtn").prop("disabled", true);

                let isPaneEmailActive = false;
                let x = document.getElementById("emailpane");
                if (window.getComputedStyle(x).display === "none") {
                    isPaneEmailActive = false;
                    self.paneActive = "MOBILE";
                }
                else {
                    isPaneEmailActive = true;
                    self.paneActive = "EMAIL";                   
                }

                let user = {}                
                user.Email = isPaneEmailActive ? $("#txtEmail").val() : null;
                user.Mobile = !isPaneEmailActive ? $("#txtMobilePhone").val() : null;

                self.sendCreatePin(user);
            }
        });

        //Access with pin
        $("#submitPinBtn").on("click", function (e) {
            e.preventDefault();
            $(this).prop("disabled", true);
            self.checkUserPin($("#pincode-input-hidden").val());
        });
    }

    checkSubmitForm() {
        var $this = $("#signin-form");
        var validate = $this.validate();
        return validate.checkForm();
    } 

    sendCreatePin(data) {
        let self = this;
        let promise = $.ajax({
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: JSON.stringify(data),
            url: '/Account/CreatePin'
        });

        promise.done(function (obj) {
            $("#signinBtn").prop("disabled", false);

            if (obj.message == "Success") {
                $("#formsignin-div").hide();
                $("#formanterpin-div").show();
            }
            else {
                self.openModal(obj.message);
            }
        });
        promise.fail(function (obj) {
            $("#signinBtn").prop("disabled", false);
            self.openModal(this.resourceManager.getResource("SignIn_ErrorStandard"));
        });
    }

    checkUserPin(pin) {
        let self = this;
        let user = {};
        user.Email = this.paneActive == "EMAIL" ? $("#txtEmail").val() : null;
        user.Mobile = this.paneActive == "MOBILE" ? $("#txtMobilePhone").val() : null;
        user.AccessPin = pin;

        let promise = $.ajax({
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: JSON.stringify(user),
            url: '/Account/CheckPin'
        });

        promise.done(function (obj) {
            window.location.href = "/";
        });
        promise.fail(function (obj) {
            $("#submitPinBtn").prop("disabled", false);
            self.openModal(this.resourceManager.getResource("SignIn_InvalidPin"));
        });
    }

    openModal(msg) {
        var modal = $('#mainModal');
        modal.find('.modal-body p').text(msg);
        var myModalEl = document.getElementById('mainModal');
        var modal = Modal.getInstance(myModalEl);
        modal.show();
    }
};

var controller = new SigninController();
controller.initializePage();
controller.registerEvents();