﻿export class ResourceManager {
    constructor(containerId) {
        //Private variables
        this.resourceMatrix = [
            //ComponentName_ResourceName_LangCode, Value
            ["Index_SelectIssueTypeOption_En", "- Select a Ticket Type -"],
            ["Index_SelectIssueTypeOption_Es", "- Selecciona un Tipo de Ticket -"],
            ["FieldEngine_FieldRequired_En", "This field is required."],
            ["FieldEngine_FieldRequired_Es", "Este campo es requerido."],
            ["FieldEngine_CheckBoxRequired_En", "This checkbox is required."],
            ["FieldEngine_CheckBoxRequired_Es", "Esta casilla es requerida."],
            ["FieldEngine_RequiredOption_En", "- Select an option -"],
            ["FieldEngine_RequiredOption_Es", "- Selecciona una opción -"],
            ["DriverHandler_EnterTruckOrDriver_En", "Enter your truck number or driver number to search"],
            ["DriverHandler_EnterTruckOrDriver_Es", "Escribe tu numero de camión o de conductor para buscarte"],
            ["DriverHandler_DriverLabel_En", "Driver:"],
            ["DriverHandler_DriverLabel_Es", "Conductor:"],
            ["SignIn_ErrorStandard_En", "An error has occured"],
            ["SignIn_ErrorStandard_Es", "Ha ocurrido un error"],
            ["SignIn_InvalidPin_En", "Invalid pin"],
            ["SignIn_InvalidPin_Es", "Pin invalido"],
            ["Issues_AllIssueTypes_En", "- All Types -"],
            ["Issues_AllIssueTypes_Es", "- Todos los Tipos -"],
            ["Issues_SelectTicketFirst_En", "Please select an ticket first."],
            ["Issues_SelectTicketFirst_Es", "Seleccione un ticket primero"],
            ["Issues_ErrorNoteCreation_En", "Error: Cannot create Issue Comment."],
            ["Issues_ErrorNoteCreation_Es", "Error: No se puede crear la commentario."],
            ["Issues_AllStatuses_En", "- All Statuses -"],
            ["Issues_AllStatuses_Es", "- Todos los Estados -"],
            ["Users_AllUserTypes_En", "- All Types -"],
            ["Users_AllUserTypes_Es", "- Todos los Tipos -"],
            ["Users_AllOrgUnits_En", "All Org Units"],
            ["Users_AllOrgUnits_Es", "Todas las Org Units"],
        ]
    }

    getResource(name) {
        var lang = localStorage['language'];
        if (!lang) {
            lang = "en";
        }
        lang = lang.charAt(0).toUpperCase() + lang.slice(1);
        var resourceRow = this.resourceMatrix.find(({ 0: n }) => n === name + "_" + lang);
        if (!resourceRow) {//Not available fallback to english
            resourceRow = this.resourceMatrix.find(({ 0: n }) => n === name + "_" + "En");
        }

        if (resourceRow) {
            return resourceRow[1];
        }

        return "Resource string not found";
    }
}
